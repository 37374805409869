.ChatsDetails {
    display: flex;
    flex-direction: column;
}

.ChatsDetails__heading {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(216, 216, 216);
    margin-bottom: 20px;
}

.ChatsDetails__heading>*:not(:last-child) {
    margin-right: 10px;
}

.ChatsDetails__heading-img {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.ChatsDetails__heading-name {
    font-size: 1.2rem;
    font-weight: bold;
    color: rgb(35, 35, 35);
}

.ChatsDetails__messages-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 10px;
    max-height: 55vh;
    overflow-y: auto;
}

.ChatsDetails__message {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.ChatsDetails__message.ChatsDetails__message--sent-by-me {
    justify-content: end;
    flex-direction: row-reverse;
}

.ChatsDetails__message-img {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    box-shadow: 2px 2px 5px rgb(0, 0, 0, .1);
}

.ChatsDetails__message--sent-by-me .ChatsDetails__message-img {
    margin-right: 0;
    margin-left: 10px;
}

.ChatsDetails__message-content {
    margin: 0;
    padding: 10px 15px;
    background-color: #fff;
    color: rgb(35, 35, 35);
    max-width: 70%;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    box-shadow: 2px 2px 5px rgb(0, 0, 0, .05);
}

.ChatsDetails__message--sent-by-me .ChatsDetails__message-content {
    background-color: var(--primary);
    color: #fff;
    border-radius: 10px;
    border-bottom-right-radius: 0;
}

.ChatsDetails__form {
    padding-top: 15px;
    border-top: 1px solid #ddd;
}

.ChatsDetails__form>*:not(:last-child) {
    margin-right: 10px;
}

.ChatsDetails__form-input {
    width: 100%;
    border: none;
    padding: 1rem;
    background-color: transparent;
}

.ChatsDetails__form-btn {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    background-color: rgb(255, 97, 35);
    color: #fff;
}